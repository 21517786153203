import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Breadcrumb, Row, Col, Input, Button, Select, Modal, message } from 'antd';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';

import Editor from '../../components/editor';
import { UPDATE_POST, QUERY_POST, QUERY_POST_BY_ID } from '../../mutations/postMutation';
import { POST_FORM } from '../../helpers/fetch';
import defaut_image from '../../images/images.png';

const EditPost = () => {
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({});
  const [fileCover, setFileCover] = useState();
  const [content, setContent] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [isPreview, setIsPreview] = useState(false);
  const history = useHistory();
  const { postId } = useParams();

  const [updatePost] = useMutation(UPDATE_POST, {
    refetchQueries: [{query: QUERY_POST}, {query: QUERY_POST_BY_ID, variables: { postId }}],
    onCompleted: (data) => {
      if (data && data.updatePost) {
        Modal.success({ title: 'Update success! ', onOk: () => { history.push('/post/list'); } });
      }
    },
    onError: () =>  Modal.error({ title: 'Update error! '})
  });

  const {data = {}, error} = useQuery(QUERY_POST_BY_ID, { variables: { postId }});

  useEffect(() => {
    if (loading) {
      const hide = message.loading('Action in progress..', 0);
      setTimeout(hide, 2500);
    }
  }, [loading]);

  useEffect(() => {
    if (content) {
      setArticle({...article, description: content});
    }
  }, [content]);

  const handleUpdate = async (type) => {
    setLoading(true);
    try {
      // note: prepare update tutorial input 
      let input = {...article};
      delete input._id;
      delete input.coverUrl;
      delete input.author;
      delete input.__typename;
  
      // note: is change post cover image
      if (fileCover) {
        let formData = new FormData();
        formData.append('file', fileCover);
  
        const uploadCoverImageResult =  await POST_FORM('/api/file-upload', formData)
  
        input.cover = uploadCoverImageResult.fileId;

        if (type === 'publish') {
          input.status =  'ACTIVE';
        }
        
        setArticle({ ...article, cover: uploadCoverImageResult.fileId });
      }
      
      await updatePost({
        variables: {
          id: article._id,
          input,
        },
      });
    } catch (error) {
      console.error('EditPost::handleUpdate error', error)
    } finally {
      setLoading(false)
    }
  };

  const handleChangeImage = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setFileCover(img);
    }
  };

  if (data) {
    console.log('data ', data);
  }
  if (error) {
    console.log('error ', error);
  }

  useEffect(() => {
    if (data._post) {
      setArticle(data._post);
      setDefaultValue(data._post.description);
    }
  }, [data._post]);

  const renderCover = () => {
    if (fileCover) {
      return URL.createObjectURL(fileCover); 
    } else if (article.coverUrl) {
      return article.coverUrl;
    } else {
      return defaut_image;
    }
  };
  
  return <div className='post'>
    <div className='breadcrumb'>
      <Breadcrumb>
        <Breadcrumb.Item>Blog</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="">{data && data._post && data._post.name}</a>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
    {
      !isPreview
        ?
        <div className='body'>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              Name:
              <Input value={article.name} onChange={(e) => { setArticle({...article, name: e.target.value }); }}/>
            </Col>
            <Col span={12}>
              Category:
              <Select value={article.category} style={{ width: '100%' }} onChange={(value) => { setArticle({...article, category: value});}}>
                <Select.Option value="Food">Food</Select.Option>
                <Select.Option value="Design">Design</Select.Option>
                <Select.Option value="Dev">Dev</Select.Option>
              </Select>
            </Col>
            <Col span={24}>
              <label htmlFor="cover">Choose a cover picture (560 x 264):</label>
              <br />
              <input type="file"
                id="cover" name="cover"
                accept="image/png, image/jpeg"
                onChange={handleChangeImage}></input>
            </Col>
            <Col span={24}>
              <img src={renderCover()} alt='sdds' width={300} height={200}/>
            </Col>
            <Col span={24}>
              <label htmlFor="shortDescription">Short description:</label>
              <Input value={article.shortDescription} onChange={(e) => { setArticle({...article, shortDescription: e.target.value }); }}/>
            </Col>
            <Col span={24}>
              <label>Description:</label>
              <Editor
                value={defaultValue}
                onChange={(newContent) => { setContent(newContent); }}
              />
            </Col>
          </Row>
          <div className='action_button'>
            <Button type="primary" onClick={() => { setIsPreview(true); }}>
              Preview
            </Button>
          </div>
        </div>
        :
        <div>
          <div className='preview'>
            <img src={renderCover()} alt='dsddss' width={600} height={400}/>
            <div className='content'>
              <h3>{article.name || 'Lorem ipsum dolor sit amet'}</h3>
              <h4>{article.author.firstName + ' ' + article.author.lastName} | {moment(article.createdAt).format('DD/MM/YYYY hh:mm:ss')}</h4>
              <div dangerouslySetInnerHTML={{ __html: article.description }} />
            </div>
          </div>
          <div className='action_button'>
            <Button className='button_dds' type="primary" onClick={() => { setIsPreview(false);} }>
              Back
            </Button>
            <Button className='button_dds' type="primary" onClick={() => handleUpdate() }>
              Update
            </Button>
          </div>
        </div>
    }
  </div>;
};

EditPost.propTypes = {};

export default EditPost;
