import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Breadcrumb, Row, Col, Input, Button, Select, Modal, message, InputNumber } from 'antd';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';

import Editor from '../../components/editor';
import { UPDATE_TUTORIAL, QUERY_TUTORIAL, QUERY_TUTORIAL_BY_ID } from '../../mutations/tutorialMutation';
import { POST_FORM } from '../../helpers/fetch';
import defaut_image from '../../images/images.png';
import { HASH_TAGS } from '../../constants';

const EditTutorial = () => {
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({});
  const [fileCover, setFileCover] = useState();
  const [content, setContent] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [isPreview, setIsPreview] = useState(false);
  const history = useHistory();
  const { tutorialId } = useParams();

  const [updateTutorial] = useMutation(UPDATE_TUTORIAL, {
    refetchQueries: [{query: QUERY_TUTORIAL},{ query: QUERY_TUTORIAL_BY_ID, variables: { tutorialId }}],
    onCompleted: (data) => {
      if (data && data.updateTutorial) {
        setLoading(true);
        Modal.success({ title: 'Update success! ', onOk: () => { history.push('/tutorial/list'); } });
      }
    },
  });

  const {data = {}, error} = useQuery(QUERY_TUTORIAL_BY_ID, { variables: { tutorialId }});

  useEffect(() => {
    if (loading) {
      const hide = message.loading('Action in progress..', 0);
      setTimeout(hide, 2500);
    }
  }, [loading]);

  useEffect(() => {
    if (content) {
      setArticle({...article, description: content});
    }
  }, [content]);

  const handleUpdate = async (type) => {
    setLoading(true);
    try {
      // note: prepare updatePostInput 
      let input = {...article};
      delete input._id;
      delete input.coverUrl;
      delete input.author;
      delete input.__typename;
  
      // note: is change post cover image
      if (fileCover) {
        let formData = new FormData();
        formData.append('file', fileCover);
  
        const uploadCoverImageResult =  await POST_FORM('/api/file-upload', formData)
  
        input.cover = uploadCoverImageResult.fileId;

        if (type === 'publish') {
          input.status =  'ACTIVE';
        }
        
        setArticle({ ...article, cover: uploadCoverImageResult.fileId });
      }
      
      await updateTutorial({
        variables: {
          id: article._id,
          input,
        },
      });
    } catch (error) {
      console.error('EditPost::handleUpdate error', error)
    } finally {
      setLoading(false)
    }
  };

  const handleChangeImage = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setFileCover(img);
    }
  };

  if (data) {
    console.log('data ', data);
  }
  if (error) {
    console.log('error ', error);
  }

  useEffect(() => {
    if (data._tutorial) {
      setArticle(data._tutorial);
      setDefaultValue(data._tutorial.description);
    }
  }, [data._tutorial]);

  const renderCover = () => {
    if (fileCover) {
      return URL.createObjectURL(fileCover); 
    } else if (article.coverUrl) {
      return article.coverUrl;
    } else {
      return defaut_image;
    }
  };
  
  const children = [];
  for (let i = 0; i < HASH_TAGS.length; i++) {
    children.push(<Select.Option key={HASH_TAGS[i]}>{`#${HASH_TAGS[i]}`}</Select.Option>);
  }
  function youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length===11)? match[7] : false;
}
  const getYoutubeEmbeddedUrl = (url) => {
    const videoId = youtube_parser(url)
    return <iframe id="ytplayer" title='url' type="text/html" width="640" height="360"
    src={`https://www.youtube.com/embed/${videoId}`}
    frameborder="0"></iframe>
  
  }
  

  return <div className='tutorial'>
    <div className='breadcrumb'>
      <Breadcrumb>
        <Breadcrumb.Item>Blog</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="">{data && data._tutorial && data._tutorial.name}</a>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
    {
      !isPreview
        ?
        <div className='body'>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              Name:
              <Input value={article.name} onChange={(e) => { setArticle({...article, name: e.target.value }); }}/>
            </Col>
            <Col span={12}>
              Category:
              <Select defaultValue={article.category} style={{ width: '100%' }} onChange={(value) => { setArticle({...article, category: value});}}>
                <Select.Option value="Worker_Engagement">Worker Engagement</Select.Option>
                <Select.Option value="Survey">Survey</Select.Option>
                <Select.Option value="Elearning">E-learning</Select.Option>
                <Select.Option value="Assessment">Assessment</Select.Option>
                <Select.Option value="Grievance">Grievance</Select.Option>
                <Select.Option value="COVID_Prevention">COVID Prevention</Select.Option>
              </Select>
            </Col>
            <Col span={24}>
              <label htmlFor="cover">Choose a cover picture (560 x 264):</label>
              <br />
              <input type="file"
                id="cover" name="cover"
                accept="image/png, image/jpeg"
                onChange={handleChangeImage}></input>
            </Col>
            <Col span={24}>
              <img src={renderCover()} alt='dsddss' width={600} height={400}/>
            </Col>
            <Col span={24}>
              <label htmlFor="shortDescription">Short description:</label>
              <Input value={article.shortDescription} onChange={(e) => { setArticle({...article, shortDescription: e.target.value }); }}/>
            </Col>
            <Col span={12}>
              <label htmlFor="videoUrl">Video URL:</label>
              <Input value={article.videoUrl} onChange={(e) => { setArticle({...article, videoUrl: e.target.value }); }}/>
            </Col>
            <Col span={12}>
              <label htmlFor="timeToRead">Time (minute):</label>
              <br></br>
              <InputNumber value={article.timeToRead} onChange={(value) => { setArticle({...article, timeToRead: value }); }}/>
            </Col>
            <Col span={24}>
              <label htmlFor="hashtags">Hash tag:</label>
              <Select
                mode="tags" 
                placeholder="Please select"
                defaultValue={[]}
                value={article.hashtags}
                onChange={(value) => { setArticle({...article, hashtags: value })}}
                style={{ width: '100%' }}
              >
                {children}
              </Select>            
            </Col>
            <Col span={24}>
              <label>Description:</label>
              <Editor
                value={defaultValue}
                onChange={(newContent) => { setContent(newContent); }}
              />
            </Col>
          </Row>
          <div className='action_button'>
            <Button type="primary" onClick={() => { setIsPreview(true); }}>
              Preview
            </Button>
          </div>
        </div>
        :
        <div>
          <div className='preview'>
            {getYoutubeEmbeddedUrl(article.videoUrl)}
            {
              article.hashtags && article.hashtags.length > 0
              ?
              <div className='hashtag-parent'>
                {
                  article.hashtags.map(
                    hashtag => <div key={hashtag} className='hashtag-item'>{hashtag.includes('#') ? hashtag : `#${hashtag}`}</div>
                  )
                }
              </div>
            :
            ''
            }
            
            <div className='content'>
              <h3>{article.name || 'Lorem ipsum dolor sit amet'}</h3>
              <div dangerouslySetInnerHTML={{ __html: article.description }} />
            </div>
          </div>
          <div className='action_button'>
            <Button className='button_dds' type="primary" onClick={() => { setIsPreview(false);} }>
              Back
            </Button>
            <Button className='button_dds' type="primary" onClick={() => handleUpdate() }>
              Update
            </Button>
          </div>
        </div>
    }
  </div>;
};

EditTutorial.propTypes = {};

export default EditTutorial;
