import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Breadcrumb, Table, Button, Modal, Form, Select, Input, message, Tooltip } from 'antd';

import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  KeyOutlined,
} from '@ant-design/icons';
import { CREATE_USER, QUERY_USER, UPDATE_USER, REMOVE_USER } from '../../mutations/userMutations';
import { QUERY_ROLE } from '../../mutations/roleMutation';

const ListUser = () => {
  const [isShowModalChangePass, setIsShowModalChangePass] = useState(false);
  const [isShowModalAddNew, setIsShowModalAddNew] = useState(false);
  const [initForm, setInitForm] = useState({});
  const [form] = Form.useForm();

  const [createUser] = useMutation(CREATE_USER, {
    refetchQueries: [{query: QUERY_USER}],
    onCompleted: (data) => {
      if (data && data.createUser) {
        message.success('Create user success');
      }
    },
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [{query: QUERY_USER}],
    onCompleted: (data) => {
      console.log('data ', data);
      if (data && data.updateUser) {
        message.success('Update user success');
      }
    },
  });
  const [removeUser] = useMutation(REMOVE_USER, {
    refetchQueries: [{query: QUERY_USER}],
    onCompleted: (data) => {
      if (data && data.removeUser) {
        message.success('Remove user success');
      }
    },
  });
  
  useEffect(() => {
    form.setFieldsValue({
      firstName: initForm.firstName,
      lastName: initForm.lastName,
      email: initForm.email,
    });
  }, [initForm]);
  const queryRoles = useQuery(QUERY_ROLE);
  const { loading, error, data } = useQuery(QUERY_USER);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  if (data) {
    console.log('data ', data);
  }
  
  const handleAddNew = () => {
    setInitForm({});
    setIsShowModalAddNew(true);
  };

  const closeModal = () => {
    setIsShowModalAddNew(false);
  };

  const handleSaveUser = () => {
    setIsShowModalAddNew(false);
  };

  const hanleDeleteUser = (id) => {
    Modal.confirm({
      title: 'Delete user?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this user?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () =>
        removeUser({
          variables: {
            id
          }
        })
    });
  };

  const handleUpdateUser = (user) => {
    console.log('initForm ', user);
    setInitForm(user);
    setIsShowModalAddNew(true);
  };

  
  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (value, item, index) => index + 1
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: ['role', 'name'],
      key: 'role, name',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value, item) => {
        return <>
          <Tooltip placement="bottom" title={'Edit user'}>
            <EditOutlined onClick={() => handleUpdateUser(item)} className='mr10'/>
          </Tooltip>
          <Tooltip placement="bottom" title={'Change password user'}>
            <KeyOutlined onClick={() => handleChangePasswordUser(item)} className='mr10'/>
          </Tooltip>
          <Tooltip placement="bottom" title={'Delete user'}>
            <DeleteOutlined onClick={() => hanleDeleteUser(item._id)}/>
          </Tooltip>
        </>;
      },
    },
  ];
  
  const handleChangePasswordUser = (user) => {
    setInitForm(user);
    setIsShowModalChangePass(true);
  }

  const onUpdatePasswordUser = (values) => {
    if (values.newPassword === values.confirmPassword) {
      console.log('id ', initForm._id);
      console.log('values.newPassword ', values.newPassword);
      updateUser({
        variables: {
          id: initForm._id,
          input: {
            password: values.newPassword,
          }
        }
      });
    } else {
      message.error('Password and confirm password dose not match');
    }
  }

  const onFinish = (values) => {
    if (initForm._id) {
      updateUser({
        variables: {
          id: initForm._id,
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            roleId: values.roleId,
          }
        }
      });
    } else {
      createUser({
        variables: {
          input: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password,
            roleId: values.roleId,
          }
        }
      });
    }
    setIsShowModalAddNew(false);
  };
  const onFinishFailed = () => {
    
  };
  
  return <div className='post'>
    <div className='breadcrumb'>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Users</Breadcrumb.Item>
      </Breadcrumb>
    </div>
    <div className='body'>
      <div className='add-new'>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNew}>Add New</Button>
      </div>
      <div className='table'>
        <Table dataSource={data.users} columns={columns} />
      </div>
    </div>
    <Modal title="Update user" visible={isShowModalAddNew} onOk={handleSaveUser} onCancel={closeModal} footer={null} getContainer={false} >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        initialValues={initForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout='vertical'
        autoComplete="off"
      >
        <Form.Item
          label="First name"
          name="firstName"
          rules={[{ required: true, message: 'Please input your first name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastName"
          rules={[{ required: true, message: 'Please input last name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input email!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Role"
          name="roleId"
          valuePropName="option"
          rules={[{ required: true, message: 'Please input role!' }]}
        >
          <Select style={{ width: 130 }} defaultValue={initForm.roleId}>
            {(queryRoles.data.roles).map(item => (
              <Select.Option key={item._id} value={item._id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {
          !initForm._id
            ?
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>
            :
            <></>
        }
        <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
    <Modal title="Change password user" visible={isShowModalChangePass} onOk={handleSaveUser} onCancel={() => setIsShowModalChangePass(false)} footer={null} getContainer={false} >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        onFinish={onUpdatePasswordUser}
        onFinishFailed={onFinishFailed}
        layout='vertical'
        autoComplete="off"
      >
        <Form.Item
          label="New password"
          name="newPassword"
          rules={[{ required: true, message: 'Please input new password!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirm Password"
          name="confirmPassword"
          rules={[{ required: true, message: 'Please input confirm password!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 20, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  </div>;
};

ListUser.propTypes = {};

export default ListUser;
