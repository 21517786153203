import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Breadcrumb, Table, Button, message, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
  EyeOutlined
} from '@ant-design/icons';
import { QUERY_TUTORIAL, UPDATE_TUTORIAL, REMOVE_TUTORIAL } from '../../mutations/tutorialMutation';

const ListTutorial = () => {

  const history = useHistory();

  const { data, loading } = useQuery(QUERY_TUTORIAL);

  const [updateTutorials] = useMutation(UPDATE_TUTORIAL, {
    refetchQueries: [{query: QUERY_TUTORIAL}],
    onCompleted: (data) => {
      if (data && data.updateTutorial) {
        message.success('Success');
      }
    },
  });

  const [removeTutorial] = useMutation(REMOVE_TUTORIAL, {
    refetchQueries: [{query: QUERY_TUTORIAL}],
    onCompleted: (data) => {
      if (data && data.updateTutorial) {
        message.success('Success');
      }
    },
  });

  const handleAddNew = () => {
    history.push('/tutorial/new');
  };

  const handlePublicTutorial = (item) => {
    console.log('_id: ', item)
    updateTutorials({
      variables: {
        id: item._id,
        input: {
          status: 'ACTIVE'
        }
      }
    });
  }
  
  const handleUnpublicTutorial = (item) => {
    updateTutorials({
      variables: {
        id: item._id,
        input: {
          status: 'INACTIVE'
        }
      }
    })
  }

  const handleUpdateTutorial = (item) => {
    history.push('/tutorial/edit/'+item._id);
  };

  const hanleDeleteTutorial = (item) => {
    Modal.confirm({
      title: 'Delete this tutorial?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this tutorial?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () =>
        removeTutorial({
          variables: {
            id: item._id
          }
        })
    });
    
  };

  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (value, item, index) => index + 1
    },
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      // render: (status) => status === 'ACTIVE' ? 'Public' : 'Draft',
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => status === 'ACTIVE' ? 'Public' : 'Draft',
    },
    {
      title: 'Created By',
      dataIndex: ['author', 'email'],

    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value, item) => {
        return <>
          <EditOutlined onClick={() => handleUpdateTutorial(item)} className='mr10'/>
          {
            item.status === 'ACTIVE'
            ?
            <EyeInvisibleOutlined onClick={() => handleUnpublicTutorial(item)} className='mr10'/>
            :
            <EyeOutlined onClick={() => handlePublicTutorial(item)} className='mr10'/>
          }
          <DeleteOutlined onClick={() => hanleDeleteTutorial(item)}/>
        </>;
      },
    },
  ];

  if (loading) return <div>Loading...</div>;
  
  return <div className='tutorial'>
    <div className='breadcrumb'>
      <Breadcrumb>
        <Breadcrumb.Item>Tutorial</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/tutorial/list">List</a>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
    <div className='body'>
      <div className='add-new'>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNew}>Add New</Button>
      </div>
      <div className='table'>
        <Table dataSource={data.tutorials} columns={columns} />
      </div>
    </div>
  </div>;
};

ListTutorial.propTypes = {};

export default ListTutorial;
