import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Breadcrumb, Row, Col, Input, Button, Select, Modal, message } from 'antd';
import moment from 'moment';

import Editor from '../../components/editor';
import { CREATE_POST, QUERY_POST } from '../../mutations/postMutation';
import { POST_FORM } from '../../helpers/fetch';
import defaut_image from '../../images/images.png';
import { useHistory } from 'react-router-dom';

const NewPost = () => {
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({});
  const [fileCover, setFileCover] = useState();
  const [content, setContent] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [isPreview, setIsPreview] = useState(false);
  const history = useHistory();

  const [createPost] = useMutation(CREATE_POST, {
    refetchQueries: [{query: QUERY_POST}],
    onCompleted: (data) => {
      if (data && data.createPost) {
        setLoading(true);
        Modal.success({ title: 'Create success! ', onOk() { history.push('/post/list'); }}, );
      }
    },
  });

  useEffect(() => {
    if (loading) {
      const hide = message.loading('Action in progress..', 0);
      setTimeout(hide, 2500);
    }
  }, [loading]);

  useEffect(() => {
    if (isPreview) {
      setDefaultValue(article.description);
    }
  }, [isPreview]);

  const handleSave = async (type) => {
    // Save image
    if (fileCover) {
      setLoading(true);
      let formData = new FormData();
      formData.append('file', fileCover);

      POST_FORM('/api/file-upload', formData)
        .then(response => {
          let input = {};
          if (type === 'publish') {
            input = {...article, cover: response._id, status: 'ACTIVE'};
          } else {
            input = {...article, cover: response._id};
          }
          setArticle({ ...article, cover: response._id });
          createPost({
            variables: {
              input,
            },
          });
        });
    } else {
      Modal.warning({title: 'Cover is required!'});
    }
  };

  const handleChangeImage = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setFileCover(img);
    }
  };
  
  useEffect(() => {
    setArticle({...article, description: content })
  }, [content]);

  
  return <div className='post'>
    <div className='breadcrumb'>
      <Breadcrumb>
        <Breadcrumb.Item>Blog</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/post/new">New</a>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
    {
      !isPreview
        ?
        <div className='body'>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              Name:
              <Input value={article.name} onChange={(e) => { setArticle({...article, name: e.target.value }); }}/>
            </Col>
            <Col span={12}>
              Category:
              <Select defaultValue={article.category} style={{ width: '100%' }} onChange={(value) => { setArticle({...article, category: value});}}>
                <Select.Option value="Worker_Engagement">Worker Engagement</Select.Option>
                <Select.Option value="Survey">Survey</Select.Option>
                <Select.Option value="Elearning">E-learning</Select.Option>
                <Select.Option value="Assessment">Assessment</Select.Option>
                <Select.Option value="Grievance">Grievance</Select.Option>
                <Select.Option value="Sustainable Development with Gopy">Sustainable Development with Gopy</Select.Option>
              </Select>
            </Col>
            <Col span={24}>
              <label htmlFor="cover">Choose a cover picture (1024 x 768):</label>
              <br />
              <input type="file"
                id="cover" name="cover"
                accept="image/png, image/jpeg"
                onChange={handleChangeImage}></input>
            </Col>
            <Col span={24}>
              <img src={fileCover ? URL.createObjectURL(fileCover) : defaut_image} alt='img' width={510} height={384}/>
            </Col>
            <Col span={24}>
              <label htmlFor="shortDescription">Short description:</label>
              <Input value={article.shortDescription} onChange={(e) => { setArticle({...article, shortDescription: e.target.value }); }}/>
            </Col>
            <Col span={24}>
              <label>Description:</label>
              <Editor
                value={defaultValue}
                onChange={(newContent) => { setContent(newContent); }}
              />
            </Col>
          </Row>
          <div className='action_button'>
            <Button type="primary" onClick={handleSave}>
              Save draft
            </Button>
            <Button type="primary" onClick={() => { setIsPreview(true); }}>
              Preview
            </Button>
          </div>
        </div>
        :
        <div>
          <div className='preview'>
            <img src={fileCover ? URL.createObjectURL(fileCover) : defaut_image} alt='img' width={1024} height={768}/>
            <div className='content'>
              <h3>{article.name || 'Lorem ipsum dolor sit amet'}</h3>
              <h4>{'Written by ABC'} | {moment().format('DD/MM/YYYY hh:mm:ss')}</h4>
              <div dangerouslySetInnerHTML={{ __html: article.description }} />
            </div>
          </div>
          <div className='action_button'>
            <Button type="primary" onClick={() => setIsPreview(false) }>
              Back
            </Button>
            <Button type="primary" onClick={() => handleSave('publish') }>
              Publish
            </Button>
          </div>
        </div>
    }
  </div>;
};

NewPost.propTypes = {};

export default NewPost;
