import React, { } from 'react';
import { useMutation } from '@apollo/react-hooks';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Form, Input, Modal, Typography, message } from 'antd';

import { SIGN_UP } from '../mutations/userMutations';
import Gopy from '../images/logo.png';

const SignUp = () => {
  document.title = 'Register - Gopy';

  const [signUp] = useMutation(SIGN_UP, {
    onCompleted: (data) => {
      if (data && data.signUp && data.signUp.token) {
        localStorage.setItem('token', data.signUp.token);

        Modal.info({
          content: 'Your account has been created successfully',
          onOk() {
            window.location.href = '/profile';
          }
        });
      } else {
        message.error('Something wrong');
      }
    },
  });

  const formSubmit = (values) => {
    if (values && values.email && values.password && values.firstName && values.lastName) {
      signUp({
        variables: {
          input: {
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
          },
        },
      });
    }
  };

  const onFinish = (values) => {
    if (values.password !== values.confirmPassword) {
      message.error('Password and confirm password dose not match');

    }
    formSubmit(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
    message.error('Failed:', errorInfo);
  };

  return (
    <div className="login">
      <div className='header'>
      </div>
      <div className='body'>
        <div className='logo'>
          <img src={Gopy} alt=""></img>
          <h3 className='title'>{'Create account'}</h3>
        </div>
        <div className='content'>
          <Form
            name="basic"
            labelCol={{ span: 20 }}
            wrapperCol={{ span: 24 }}
            layout={'vertical'}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="First name"
              name="firstName"
              rules={[{ required: true, message: 'Please input your first name!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Last name"
              name="lastName"
              rules={[{ required: true, message: 'Please input your last name!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[{ required: true, message: 'Please input confirm password!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 0, span: 16 }}>
              <Typography.Link href="/login">Login here?</Typography.Link>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
              <Button type="primary" htmlType="submit">
								Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SignUp);
