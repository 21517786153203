import gql from 'graphql-tag';

export const CREATE_ROLE = gql`
	mutation createRoleMutation($input: RoleInput!) {
		createRole(input: $input) {
			name
		}
	}
`;

export const QUERY_ROLE = gql`
	query rolesQuery {
		roles {
			_id,
			name,
			code,
			description,
			roles,
			createdAt
		}
	}
`;

export const UPDATE_ROLE = gql`
	mutation updateRoleMutation($id: ID!, $input: RoleInput!) {
		updateRole(id: $id, input: $input) {
			_id,
			name
		}
	}
`;

export const REMOVE_ROLE = gql`
	mutation removeRoleMutation($id: ID!) {
		removeRole(id: $id) {
			_id
		}
	}
`;