import gql from 'graphql-tag';

export const QUERY_POST = gql`
	query postsQuery {
		posts {
			_id,
			name,
			slug,
			cover,
			shortDescription,
			description,
			status,
			category,
			createdAt,
			author {
				firstName
				lastName
				email
			}
		}
	}
`;

export const CREATE_POST = gql`
	mutation createPostMutation($input: PostInput!) {
		createPost(input: $input) {
			_id
		}
	}
`;

export const UPDATE_POST = gql`
	mutation updatePostMutation($id: ID!,$input: PostInput!) {
		updatePost(id: $id, input: $input) {
			_id
		}
	}
`;

export const REMOVE_POST = gql`
	mutation removePostMutation($id: ID!) {
		removePost(id: $id) {
			_id
		}
	}
`;

export const QUERY_POST_BY_ID = gql`
	query postsQuery($postId: ID) {
		_post(id: $postId) {
			_id,
			name,
			slug,
			cover,
			shortDescription,
			coverUrl,
			description,
			status,
			category,
			createdBy,
			createdAt,
			author {
				firstName,
				lastName
			}
		}
	}
`;
