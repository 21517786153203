import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { Breadcrumb, Row, Col, Input, Button, Select, Modal, message, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';

import { HASH_TAGS } from '../../constants';
import Editor from '../../components/editor';
import { CREATE_TUTORIAL, QUERY_TUTORIAL } from '../../mutations/tutorialMutation';
import { POST_FORM } from '../../helpers/fetch';
import defaut_image from '../../images/images.png';

const NewTutorial = () => {
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({});
  const [fileCover, setFileCover] = useState();
  const [content, setContent] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [isPreview, setIsPreview] = useState(false);
  const history = useHistory();

  const [createTutorial] = useMutation(CREATE_TUTORIAL, {
    refetchQueries: [{query: QUERY_TUTORIAL}],
    onCompleted: (data) => {
      if (data && data.createTutorial) {
        setLoading(true);
        Modal.success({ title: 'Create success! '});
        history.push('/tutorial/list');
      }
    },
  });

  useEffect(() => {
    if (loading) {
      const hide = message.loading('Action in progress..', 0);
      setTimeout(hide, 2500);
    }
  }, [loading]);

  useEffect(() => {
    if (content) {
      setArticle({...article, description: content});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    if (isPreview) {
      setDefaultValue(article.description);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreview]);

  const children = [];
  for (let i = 0; i < HASH_TAGS.length; i++) {
    children.push(<Select.Option key={HASH_TAGS[i]}>{`#${HASH_TAGS[i]}`}</Select.Option>);
  }
  function youtube_parser(url){
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length===11)? match[7] : false;
}
  const getYoutubeEmbeddedUrl = (url) => {
    const videoId = youtube_parser(url)
    return <iframe id="ytplayer" title='url' type="text/html" width="640" height="360"
    src={`https://www.youtube.com/embed/${videoId}`}
    frameborder="0"></iframe>
  
  }
  const handleBack = () => {
    setIsPreview(false);
  }
  const handleSave = async (type) => {
    // Save image
    if (fileCover) {
      setLoading(true);
      let formData = new FormData();
      formData.append('file', fileCover);

      POST_FORM('/api/file-upload', formData)
        .then(response => {
          let input = {};
          if (type === 'publish') {
            input = {...article, cover: response._id, status: 'ACTIVE'};
          } else {
            input = {...article, cover: response._id};
          }
          setArticle({ ...article, cover: response._id });
          createTutorial({
            variables: {
              input,
            },
          });
        });
    } else {
      Modal.warning({title: 'Cover is required!'});
    }
  };

  const handleChangeImage = event => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setFileCover(img);
    }
  };
  
  return <div className='tutorial'>
    <div className='breadcrumb'>
      <Breadcrumb>
        <Breadcrumb.Item>Tutorial</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/tutorial/new">New</a>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
    {
      !isPreview
        ?
        <div className='body'>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              Name:
              <Input value={article.name} onChange={(e) => { setArticle({...article, name: e.target.value }); }}/>
            </Col>
            <Col span={12}>
              Category:
              <Select defaultValue={article.category} style={{ width: '100%' }} onChange={(value) => { setArticle({...article, category: value});}}>
                <Select.Option value="Worker_Engagement">Worker Engagement</Select.Option>
                <Select.Option value="Survey">Survey</Select.Option>
                <Select.Option value="Elearning">E-learning</Select.Option>
                <Select.Option value="Assessment">Assessment</Select.Option>
                <Select.Option value="Grievance">Grievance</Select.Option>
                <Select.Option value="COVID_Prevention">COVID Prevention</Select.Option>
              </Select>
            </Col>
            <Col span={24}>
              <label htmlFor="cover">Choose a cover picture (560 x 264):</label>
              <br />
              <input type="file"
                id="cover" name="cover"
                accept="image/png, image/jpeg"
                onChange={handleChangeImage}></input>
            </Col>
            <Col span={24}>
              <img src={fileCover ? URL.createObjectURL(fileCover) : defaut_image} alt='img' width={512} height={384}/>
            </Col>
            <Col span={24}>
              <label htmlFor="shortDescription">Short description:</label>
              <Input value={article.shortDescription} onChange={(e) => { setArticle({...article, shortDescription: e.target.value }); }}/>
            </Col>
            <Col span={12}>
              <label htmlFor="videoUrl">Video URL:</label>
              <Input value={article.videoUrl} onChange={(e) => { setArticle({...article, videoUrl: e.target.value }); }}/>
            </Col>
            <Col span={12}>
              <label htmlFor="timeToRead">Time (minute):</label>
              <br></br>
              <InputNumber value={article.timeToRead} onChange={(value) => { setArticle({...article, timeToRead: value }); }}/>
            </Col>
            <Col span={24}>
              <label htmlFor="hashtags">Hash tag:</label>
              <Select
                mode="tags" 
                placeholder="Please select"
                defaultValue={[]}
                value={article.hashtags}
                onChange={(value) => { setArticle({...article, hashtags: value })}}
                style={{ width: '100%' }}
              >
                {children}
              </Select>            
            </Col>
            <Col span={24}>
              <label>Description:</label>
              <Editor
                value={defaultValue}
                onChange={(newContent) => { setContent(newContent); }}
              />
            </Col>
          </Row>
          <div className='action_button'>
            <Button type="primary" onClick={() => { setIsPreview(true); }}>
              Preview
            </Button>
          </div>
        </div>
        :
        <div>
          <div className='preview'>
            {getYoutubeEmbeddedUrl(article.videoUrl)}
            {
              article.hashtags && article.hashtags.length > 0
              ?
              <div className='hashtag-parent'>
                {
                  article.hashtags.map(
                    hashtag => <div key={hashtag} className='hashtag-item'>{hashtag.includes('#') ? hashtag : `#${hashtag}`}</div>
                  )
                }
              </div>
            :
            ''
            }
            
            <div className='content'>
              <h3>{article.name || 'Lorem ipsum dolor sit amet'}</h3>
              <div dangerouslySetInnerHTML={{ __html: article.description }} />
            </div>
          </div>
          <div className='action_button'>
            <Button type="primary" onClick={() => handleBack() }>
              Back
            </Button>
            <Button type="primary" onClick={() => handleSave('not-publish') }>
              Save and not publish
            </Button>
            <Button type="primary" onClick={() => handleSave('publish') }>
              Publish
            </Button>
          </div>
        </div>
    }
  </div>;
};

NewTutorial.propTypes = {};

export default NewTutorial;
