import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Breadcrumb, Table, Button, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { QUERY_POST, UPDATE_POST, REMOVE_POST } from '../../mutations/postMutation';

const ListPost = () => {
  const [listPost, setListPost] = useState([]);
  const history = useHistory();

  const { data, loading, error } = useQuery(QUERY_POST);

  const [updatePost] = useMutation(UPDATE_POST, {
    refetchQueries: [{query: QUERY_POST}],
    onCompleted: (data) => {
      if (data && data.posts) {
      }
    },
  });

  const [removePost] = useMutation(REMOVE_POST, {
    refetchQueries: [{query: QUERY_POST}],
    onCompleted: (data) => {
      if (data && data.posts) {
      }
    },
  });


  const handleAddNew = () => {
    history.push('/post/new');
  };

  const handleUpdatePost = (item) => {
    history.push('/post/edit/'+item._id);
  }

  const handleUnpublicTutorial = (item) => {
    updatePost({
      variables: {
        id: item._id,
        input: {
          status: 'INACTIVE'
        }
      }
    });
  };

  const handlePublicTutorial = (item) => {
    updatePost({
      variables: {
        id: item._id,
        input: {
          status: 'ACTIVE'
        }
      }
    });
  };

  const hanleDeletePost = (item) => {
    Modal.confirm({
      title: 'Delete this blog?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this blog?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () =>
        removePost({
          variables: {
            id: item._id
          }
        })
    });
  };

  const columns = [
    {
      title: 'No',
      key: 'index',
      width: '5%',
      render: (value, item, index) => index + 1
    },
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (status) => status === 'ACTIVE' ? 'Public' : 'Draft',
    },
    {
      title: 'Created By',
      dataIndex: ['author', 'email'],
      width: '15%',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value, item) => {
        return <>
          <EditOutlined onClick={() => handleUpdatePost(item)} className='mr10'/>
          {
            item.status === 'ACTIVE'
            ?
            <EyeInvisibleOutlined onClick={() => handleUnpublicTutorial(item)} className='mr10'/>
            :
            <EyeOutlined onClick={() => handlePublicTutorial(item)} className='mr10'/>
          }
          <DeleteOutlined onClick={() => hanleDeletePost(item._id)}/>
        </>;
      },
    },
  ];

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  return <div className='post'>
    <div className='breadcrumb'>
      <Breadcrumb>
        <Breadcrumb.Item>Post</Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/post/list">List</a>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
    <div className='body'>
      <div className='add-new'>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNew}>Add New</Button>
      </div>
      <div className='table'>
        <Table dataSource={data.posts} columns={columns} />
      </div>
    </div>
  </div>;
};

ListPost.propTypes = {};

export default ListPost;
