import React, { useState, useContext } from 'react';
import { Layout, Menu, Avatar } from 'antd';
import { useHistory, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import {
  PieChartOutlined,
  KeyOutlined,
  FormOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { withRouter } from 'react-router-dom';

import logo from '../images/logo.png';
import Loading from './Loading';
import { GET_ME } from '../mutations/userMutations';
import { AuthContext } from '../contexts/auth';

const { Content, Sider } = Layout;
const { SubMenu } = Menu;

const LayoutPage = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { user, authDispatch } = useContext(AuthContext)

  let history = useHistory();
  const { loading, data } = useQuery(GET_ME);

  const onCollapse = isCollapsed => {
    setCollapsed(isCollapsed);
  };
  
  const handleClick = (e) => {
    history.push(`/${e.key}`);
  };

  if (loading) {
    return <Loading />;
  }

  if (!data || !data.getMe || !data.getMe._id) {
    return (
      <Redirect
        to={`/login?redirect=${encodeURIComponent(window.location.href)}`}
      />
    );
  }

  const handleClickProfile = () => {
    history.push('/profile');
  }

  if (data && data.getMe) {
    authDispatch({
      type: 'login',
      data: data.getMe
    });
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider className={'sider'} collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <div>
          <div className='sider__logo'>
            <img src={logo} alt='logo'></img>
          </div>
          <Menu className={'sider__menu'} defaultSelectedKeys={['dashboard']} mode="inline" onClick={handleClick}>
            {
              ['SUPPERADMIN', 'ADMIN'].includes(data.getMe.role.code) 
              &&
              <Menu.Item className='sider__menu__item' key="dashboard" icon={<PieChartOutlined />}>
                Dashboard
              </Menu.Item>
            }
            {
              ['SUPPERADMIN', 'ADMIN', 'BLOGADMIN'].includes(data.getMe.role.code) 
              &&
              <SubMenu className='sider__menu__item' key="sub1" icon={<FormOutlined />} title="Post">
                <Menu.Item className='sider__menu__item' key="post/list">All Posts</Menu.Item>
                <Menu.Item className='sider__menu__item' key="post/new">Add New</Menu.Item>
              </SubMenu>
            }
            {
              ['SUPPERADMIN', 'ADMIN', 'TUTORIALADMIN'].includes(data.getMe.role.code) 
              &&
              <SubMenu className='sider__menu__item' key="sub2" icon={<FormOutlined />} title="Tutorial">
                <Menu.Item className='sider__menu__item' key="tutorial/list">All Tutorial</Menu.Item>
                <Menu.Item className='sider__menu__item' key="tutorial/new">Add New</Menu.Item>
              </SubMenu>
            }
            {
              ['SUPPERADMIN'].includes(data.getMe.role.code) 
              &&
              <Menu.Item className='sider__menu__item' key="users" icon={<UserOutlined />}>
                Users
              </Menu.Item>
            }
            {
              ['SUPPERADMIN'].includes(data.getMe.role.code) 
              &&
              <Menu.Item className='sider__menu__item' key="roles" icon={<KeyOutlined />}>
                Roles
              </Menu.Item>
            }
          </Menu>
        </div>
        <div className='sider__user'>
          <div className='sider__user__content' onClick={handleClickProfile}>
            {
              user.avatarUrl
                ?
                <Avatar size={'medium'} src={user.avatarUrl} />
                :
                <Avatar size={'medium'} icon={<UserOutlined />} />
              }
            
            <div className='sider__user__content__info'> 
              <span>{data.getMe.firstName + ' ' + data.getMe.lastName}</span>
              <span>{data.getMe.email}</span>
            </div>
          </div>
        </div>
      </Sider>

      <Layout>
        <Content className="site-layout">
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

LayoutPage.propTypes = {
  children: PropTypes.node,
  selectedKey: PropTypes.string
};

export default withRouter(LayoutPage);
