
export const POST = (url, data) =>
  fetch(process.env.REACT_APP_API_URL + url, {
    method: 'post',
    headers: {
      'content-type': 'application/json',
      'cache-control': 'no-cache',
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());

export const POST_FORM = (url, formData) => {
  const token = localStorage.getItem('token');
  return fetch(process.env.REACT_APP_API_URL + url, {
    method: 'post',
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
    body: formData,
  }).then((res) => res.json());
};
