import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Breadcrumb, Avatar, Col, Input, Button, Select, Form, message } from 'antd';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';

import { AuthContext } from '../contexts/auth';
import { POST_FORM } from '../helpers/fetch';
import { UPDATE_USER } from '../mutations/userMutations';

const Profile = () => {
  const [initForm, setInitForm] = useState({});
  const [form] = Form.useForm();
  const avatarRef = useRef();
  const auth = useContext(AuthContext);

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      if (data && data.updateUser) {
        console.log('data ', data);
        message.success('Update user success');
        auth.authDispatch({
          type: 'updateUser',
          data: data.updateUser
        })
      }
    },
  });

  useEffect(() => {
    setInitForm(auth.user);
  }, [auth.user]);

  useEffect(() => {
    if (initForm && initForm._id) {
      form.setFieldsValue({
        firstName: initForm.firstName,
        lastName: initForm.lastName,
        email: initForm.email,
        role: initForm.role.name,
      });
    }
  }, [initForm]);

  const onFinish = (values) => {
    updateUser({variables: {
      id: auth.user._id,
      input: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      }
    }});
  };
  const onFinishFailed = () => {
    
  };

  const changeAvatar = () => { avatarRef.current.click(); }
  const handleChangeImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let formData = new FormData();
      formData.append('file', img);

      console.log('auth.user ', auth.user)
      POST_FORM('/api/file-upload', formData)
        .then(response => {
          updateUser({variables: {
            id: auth.user._id,
            input: {
              avatar: response._id
            }
          }});
        });
    }
  }

  return <div className='profile'>
    <div className='content'>
      <div className='avatar'>
        {
          auth.user.avatarUrl
            ?
            <Avatar size={100} src={auth.user.avatarUrl} />
            :
            <Avatar size={100} />
        }
        <div className='change_avatar'>
          <Button onClick={changeAvatar}>Change Avatar</Button>
          <input type="file"
            ref={avatarRef}
            id="cover" name="cover"
            accept="image/png, image/jpeg"
            style={{display: 'none'}}
            onChange={handleChangeImage}
            ></input>
        </div>
      </div>
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        initialValues={initForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout='vertical'
        autoComplete="off"
      >
        <Form.Item
          label="First name"
          name="firstName"
          rules={[{ required: true, message: 'Please input your first name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last name"
          name="lastName"
          rules={[{ required: true, message: 'Please input last name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input email!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Change password
          </Button>
          <Button type="primary" htmlType="submit" style={{ marginLeft: 10 }}>
            Update
          </Button>
        </Form.Item>
      </Form>
    </div>
  </div>;
};

Profile.propTypes = {};

export default Profile;
