import gql from 'graphql-tag';

export const QUERY_TUTORIAL = gql`
	query tutorialsQuery {
		tutorials {
			_id,
			name,
			slug,
			cover,
			shortDescription,
			description,
			status,
			category,
			createdAt,
			author {
				firstName
				lastName
				email
			}
		}
	}
`;

export const QUERY_TUTORIAL_BY_ID = gql`
	query tutorialQuery($tutorialId: ID) {
		_tutorial(id: $tutorialId) {
			_id,
			name,
			slug,
			cover,
			shortDescription,
			coverUrl,
			description,
			status,
			category,
			createdBy,
			createdAt,
			videoUrl,
			hashtags,
			timeToRead,
			author {
				firstName,
				lastName
			}
		}
	}
`;


export const CREATE_TUTORIAL = gql`
	mutation createTutorialMutation($input: TutorialInput!) {
		createTutorial(input: $input) {
			_id
		}
	}
`;

export const UPDATE_TUTORIAL = gql`
	mutation updateTutorialMutation($id: ID!,$input: TutorialInput!) {
		updateTutorial(id: $id, input: $input) {
			_id
		}
	}
`;

export const REMOVE_TUTORIAL = gql`
	mutation removeTutorialMutation($id: ID!) {
		removeTutorial(id: $id) {
			_id
		}
	}
`;