import gql from 'graphql-tag';

export const SIGN_UP = gql`
	mutation signUpMutation(
		$input: UserInput!
	) {
		signUp(
			input: $input
		) {
			_id
			firstName
			lastName
			email
			avatar
			avatarUrl
			token
		}
	}
`;

export const LOGIN = gql`
	mutation logInMutation(
		$input: UserInput!
	) {
		logIn(
			input: $input
		) {
			_id
			firstName
			lastName
			email
			avatar
			avatarUrl
			token
		}
	}
`;

export const CREATE_USER = gql`
	mutation createUserMutation($input: UserInput!) {
		createUser(input: $input) {
			_id
		}
	}
`;

export const QUERY_USER = gql`
	query usersQuery {
		users {
			_id,
			email,
			firstName,
			lastName,
			avatar
			avatarUrl
			roleId
			role {
				_id
				name
			}
			createdAt
		}
	}
`;

export const UPDATE_USER = gql`
	mutation updateUserMutation($id: ID!, $input: UserInput!) {
		updateUser(id: $id, input: $input) {
			_id,
			email,
			firstName,
			lastName,
			createdAt,
			avatar,
			avatarUrl,
			role {
				name
				code
				roles
			}
		}
	}
`;

export const REMOVE_USER = gql`
	mutation removeUserMutation($id: ID!) {
		removeUser(id: $id) {
			_id
		}
	}
`;


export const GET_ME = gql`
	query getMe {
		getMe {
			_id,
			email,
			firstName,
			lastName,
			createdAt,
			avatar,
			avatarUrl,
			role {
				name
				code
				roles
			}
		}
	}
`;