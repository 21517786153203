import React, {} from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from './LayoutPage';

function PrivateRoute ({component: Component, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        <Layout>
          <Component {...props} />
        </Layout>}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.any,
  token: PropTypes.string,
  location: PropTypes.object
};

export default PrivateRoute;
