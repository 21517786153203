import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';

import moment from 'moment';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Breadcrumb, Table, Button, Modal, Row, Col, Input, Checkbox, message } from 'antd';

import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { CREATE_ROLE, QUERY_ROLE, UPDATE_ROLE, REMOVE_ROLE } from '../../mutations/roleMutation';

const ListRole = () => {

  const [isShowModalAddNew, setIsShowModalAddNew] = useState(false);
  const [checkedListBlog, setCheckedListBlog] = useState([]);
  const [checkedListTutorial, setCheckedListTutorial] = useState([]);
  const [indeterminateBlog, setIndeterminateBlog] = useState(true);
  const [indeterminateTutorial, setIndeterminateTutorial] = useState(true);
  const initFormData = {
    name: '',
    code: '',
    description: '',
    roles: {
      blog: {
        all: false,
        edit: false,
        publish: false,
        unpublish: false,
        delete: false
      },
      tutorial: {
        all: false,
        edit: false,
        publish: false,
        unpublish: false,
        delete: false
      },
    }
  };

  const [formData, setFormData] = useState(initFormData);

  const [createRole] = useMutation(CREATE_ROLE, {
    refetchQueries: [{query: QUERY_ROLE}],
    onCompleted: (data) => {
      if (data && data.createRole) {
        message.success('Create role success');
      }
    },
  });
  const [updateRole] = useMutation(UPDATE_ROLE, {
    refetchQueries: [{query: QUERY_ROLE}],
    onCompleted: (data) => {
      if (data && data.updateRole) {
        console.log('data ', data);
        message.success('Update role success');
      }
    },
  });
  const [removeRole] = useMutation(REMOVE_ROLE, {
    refetchQueries: [{query: QUERY_ROLE}],
    onCompleted: (data) => {
      if (data && data.removeRole) {
        message.success('Remove role success');
      }
    },
  });

  const { loading, error, data } = useQuery(QUERY_ROLE);

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  if (data) {
    console.log('data ', data);
  }

  const handleAddNew = () => {
    setFormData(initFormData);
    setIsShowModalAddNew(true);
  };

  const closeModal = () => {
    setIsShowModalAddNew(false);
  };

  const handleSaveRole = () => {
    setIsShowModalAddNew(false);
    if (formData._id) {
      const id = formData._id;
      delete formData._id;
      delete formData.__typename;
      updateRole({
        variables: {
          id,
          input: {
            name: formData.name,
            code: formData.code,
            description: formData.description,
            roles: formData.roles
          },
        },
      });
    } else {
      createRole({
        variables: {
          input: formData,
        },
      });
    }
  };

  const hanleDeleteRole = (id) => {
    Modal.confirm({
      title: 'Delete role?',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure delete this role?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () =>
        removeRole({
          variables: {
            id
          }
        })
    });
  };

  const handleUpdateRole = (item) => {
    setIndeterminateBlog(false);
    setIndeterminateTutorial(false);

    const ltTut = plainOptions.map(option => {
      if (item.roles.tutorial[option.toLowerCase()]) return option;
      else return;
    }).filter(n => !!n);
    setCheckedListTutorial(ltTut);
    const ltBlog = plainOptions.map(option => {
      if (item.roles.blog[option.toLowerCase()]) return option;
      else return;
    }).filter(n => !!n);
    setCheckedListBlog(ltBlog);

    setFormData(item);
    setIsShowModalAddNew(true);
  };

  const plainOptions = ['Edit', 'Publish', 'UnPublish', 'Delete'];
  
  const columns = [
    {
      title: 'No',
      key: 'index',
      render: (value, item, index) => index + 1
    },
    {
      title: 'Role',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => moment(createdAt).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (value, item) => {
        return <>
          <EditOutlined onClick={() => handleUpdateRole(item)} className='mr10'/>
          <DeleteOutlined onClick={() => hanleDeleteRole(item._id)}/>
        </>;
      },
    },
  ];
  
  return <div className='post'>
    <div className='breadcrumb'>
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Roles</Breadcrumb.Item>
      </Breadcrumb>
    </div>
    <div className='body'>
      <div className='add-new'>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAddNew}>Add New</Button>
      </div>
      <div className='table'>
        <Table dataSource={data.roles} columns={columns} />
      </div>

    </div>
    <Modal title="Add New" visible={isShowModalAddNew} onOk={handleSaveRole} onCancel={closeModal} width={900}>
      <Row>
        <Col span={12} style={{ padding: 10 }}>
          <div className='mt10 mb10'>Name*</div>
          <Input 
            value={formData.name}
            onChange={(e) => { setFormData({...formData, name: e.target.value }); }}
          />
          <div className='mt10 mb10'>Code*</div>
          <Input 
            value={formData.code}
            onChange={(e) => { setFormData({...formData, code: e.target.value }); }}
          />
          <div className='mt10 mb10'>Description</div>
          <Input.TextArea 
            value={formData.description}
            onChange={(e) => { setFormData({...formData, description: e.target.value }); }}
          />
        </Col>
        <Col span={12} style={{ padding: 10 }}>
          <Checkbox 
            className='mt10 mb10' 
            indeterminate={indeterminateBlog} 
            onChange={(e) => {
              setIndeterminateBlog(false);
              let tempObj = {};
              if (e.target.checked) {
                tempObj = { all: true, edit: true, update: true, publish: true, unpublish: true, delete: true};
                setCheckedListBlog(plainOptions);
              } else {
                tempObj = { all: false, edit: false, update: false, publish: false, unpublish: false, delete: false};
                setCheckedListBlog([]);
              }
              setFormData({...formData, roles: {...formData.roles, blog: tempObj}});
            }} 
            checked={formData.roles.blog.all}
          >
            Blogs
          </Checkbox>
          <br />
          <Checkbox.Group className='mt10 mb10 ml10' options={plainOptions} value={checkedListBlog} 
            onChange={(list) => {
              let tempObj = {};
              plainOptions.map(option => {
                tempObj[option.toLowerCase()] = list.includes(option);
              });

              tempObj['all'] = list.length === plainOptions.length;
              setCheckedListBlog(list);
              setFormData({...formData, roles: {...formData.roles, blog: tempObj}});
            }} 
          />
          <br />
          <Checkbox 
            className='mt10 mb10' 
            indeterminate={indeterminateTutorial} 
            onChange={(e) => {
              setIndeterminateTutorial(false);
              let tempObj = {};
              if (e.target.checked) {
                tempObj = { edit: true, update: true, publish: true, unpublish: true, delete: true};
                setCheckedListTutorial(plainOptions);
              } else {
                tempObj = { all: false, edit: false, update: false, publish: false, unpublish: false, delete: false};
                setCheckedListTutorial([]);
              }
              setFormData({...formData, roles: {...formData.roles, tutorial: tempObj}});
            }} 
            checked={formData.roles.tutorial.edit && 
              formData.roles.tutorial.publish && formData.roles.tutorial.unpublish &&
              formData.roles.tutorial.delete
            }
          >
            Tutorials
          </Checkbox>
          <br />
          <Checkbox.Group 
            className='mt10 mb10 ml10' 
            options={plainOptions} 
            value={checkedListTutorial} 
            onChange={(list) => {
              let tempObj = {};
              plainOptions.map(option => {
                tempObj[option.toLowerCase()] = list.includes(option);
              });

              tempObj['all'] = list.length === plainOptions.length;
              setCheckedListTutorial(list);
              setFormData({...formData, roles: {...formData.roles, tutorial: tempObj}});
            }} 
          />
        </Col>
      </Row>
    </Modal>
  </div>;
};

ListRole.propTypes = {};

export default ListRole;
