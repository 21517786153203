import React, { createContext, useReducer } from 'react';
import { node } from 'prop-types';

const initValue = {
  user: {},
  isLogin: false,
  isLoading: true,
  token: localStorage.getItem('token')
};
export const AuthContext = createContext(initValue);

const AuthReducer = (state, { type, data }) => {
  switch (type) {
  case 'login': {
    if (state && !state.user._id) {
      return {...state, user: data }
    }
    return state;
  }
  case 'updateUser': {
    return {...state, user: data }
  }
  default: {
    return state;
  }
  }
};
const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initValue);

  return (
    <AuthContext.Provider
      value={{ ...state, authDispatch: dispatch }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: node,
};

AuthProvider.defaultProps = {
  children: null,
};

export default AuthProvider;
