import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Form, Input, Modal, Typography, message } from 'antd';
import queryString from 'query-string';

import Gopy from '../images/logo.png';
import { POST } from '../helpers/fetch';

const Login = () => {
  document.title = 'Gopy';

  const history = useHistory();

  const formSubmit = (values) => {
    if (values && values.email && values.password) {
      POST('/api/login', { email: values.email, password: values.password})
        .then((response) => {
          const redirect = queryString.parse(window.location.search, {
            ignoreQueryPrefix: true,
          }).redirect;
  
          localStorage.setItem('token', response.token);

          window.location.href = redirect || window.location.origin;  
        });
    } else {
      message.error('Something wrong');
    }
  };

  const onFinish = (values) => {
    formSubmit(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  
  return (
    <div className="login">
      <div className='header'>
      </div>
      <div className='body'>
        <div className='logo'>
          <img src={Gopy} alt=""></img>
          <h3 className='title'>{'register_account'}</h3>
        </div>
        <div className='content'>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
            layout={'vertical'}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item name="sfsdfsd" wrapperCol={{ offset: 0, span: 16 }}>
              <Typography.Link href="/signup">Create account?</Typography.Link>
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
              <Button type="primary" htmlType="submit">
								Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Login);
