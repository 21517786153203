import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { Modal } from 'antd';

import './styles/main.scss';
import './App.css';

import Loading from './components/Loading';
import PrivateRoute from './components/PrivateRoute';
import Page404 from './pages/404';
import SignUp from './pages/SignUp';
import Login from './pages/Login';

import ListPost from './pages/posts/List';
import NewPost from './pages/posts/New';
import Dashboard from './pages/Dashboard';
import ListRole from './pages/roles/List';
import ListUser from './pages/users/List';
import Profile from './pages/profile';

import ListTutorial from './pages/tutorials/List';
import NewTutorial from './pages/tutorials/New';
import AuthProvider from './contexts/auth';
import EditPost from './pages/posts/Edit';
import EditTutorial from './pages/tutorials/Edit';

let errorTimeout = null;

const token = localStorage.getItem('token');

console.log('token ', token)
const client = new ApolloClient({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
  headers: {
    authorization: `Bearer ${token}`
  },
  credentials: 'include',
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      clearTimeout(errorTimeout);
      errorTimeout = setTimeout(() => {
        const error = graphQLErrors[0].message;
        Modal.warning({
          title: error,
        });
      }, 500);
    }
    if (networkError) {
      console.log(networkError);
    }
  },
});

const App = () => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/signup" component={SignUp} />

              <PrivateRoute path="/post/list" component={ListPost} />
              <PrivateRoute path="/post/new" component={NewPost} />
              <PrivateRoute path="/post/edit/:postId" component={EditPost} />

              <PrivateRoute path="/tutorial/list" component={ListTutorial} />
              <PrivateRoute path="/tutorial/new" component={NewTutorial} />
              <PrivateRoute path="/tutorial/edit/:tutorialId" component={EditTutorial} />

              <PrivateRoute path="/roles" component={ListRole} />
              <PrivateRoute path="/users" component={ListUser} />
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute path="/" component={Dashboard} />
            
              <Route path="/:pafe" component={Page404} />
            </Switch>
          </Suspense>
        </BrowserRouter>
      </AuthProvider>
    </ApolloProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

export default App;
